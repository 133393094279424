import { useData } from "../../context";

import Partner from "../Elements/Partner/Partner";
import Text from "../Elements/Text/Text";

import "./Partners.css";

const Partners = () => {
    const { partnersList, content, language} = useData();

    return (
        <div className="partners block" >
            <Text big={content && content[language]?.partners_title} small={content && content[language]?.partners_text} />
            <div className="partners_list">
                {partnersList && partnersList.map((partner) => {
                    return (<Partner
                        img={partner.preview}
                        link={partner.link}
                    />)
                })}
            </div>
        </div>
    )
}

export default Partners;