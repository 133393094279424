import { useData } from '../../../context';

import PromoBlock from '../PromoBlock/PromoBlock';

import './Card.css';

function Card({ img, text, code, onClick, online }) {

    const {  addNotifi, notifiTypes } = useData();

    return (
        <div className="card" onClick={onClick}>

            <img src={img} alt="img" />
            <img className="status" src={require(online === "on" ? "../../../media/svg/online.svg" : "../../../media/svg/offline.svg").default} alt="" />
            <div className="card_info">{text}</div>
            <PromoBlock code={code} onClick={() => {
                navigator.clipboard.writeText(code);

                const notifi = {
                    type: notifiTypes.successfull,
                    time: 2000,
                    text: "Промокод успешно скопирован",
                }

                addNotifi(notifi);
            }} />
        </div>
    )
}

export default Card;