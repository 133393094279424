import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';

import { useData } from '../../context';

import './Header.css'

import { ReactComponent as Instagram_svg } from "../../media/svg/social_instagram.svg";
import { ReactComponent as Facebook_svg } from "../../media/svg/social_facebook.svg";
import { ReactComponent as Telegram_svg } from "../../media/svg/social_telegram.svg";
import { ReactComponent as Youtube_svg } from "../../media/svg/social_youtube.svg";
import { ReactComponent as Line_svg } from "../../media/svg/line.svg";

function Header() {

    const { mediaLinks, getHeaderLinks, language, setLanguage, content, setModaleType, user, logout, } = useData();

    const navigate = useNavigate();
    const location = useLocation();

    const [isOpen, setOpen] = useState(false);
    const [adaptive, setAdaptive] = useState(null);
    const [languageMenuIsOpen, setLanguageMenu] = useState(false);
    const languages = [];

    const handleAuthorization = () => {
        setModaleType("authorization");
    }

    const handleLogOut = () => {
        logout();
    }

    for (let language in content) {
        languages.push(language);
    }

    useEffect(() => {
        getHeaderLinks();
    }, [])


    useEffect(() => {
        if (!language || localStorage.getItem("language") && content && !content[localStorage.getItem("language")]) {
            if (content) {
                localStorage.setItem("language", Object.keys(content)[0]);
                setLanguage(Object.keys(content)[0]);
                return;
            }

        }
        localStorage.setItem("language", language);
    }, [language, content])

    const scrollFunc = (path, offset) => {
        if (location.pathname !== "/") navigate("/");
        setTimeout(() => scroller.scrollTo(path, { offset: offset, smooth: true }), location.pathname !== "/" ? 0 : 300);
        if (isOpen) setOpen(false)
    }

    const toMainPage = () => {
        if (location.pathname !== "/")
            navigate("/");
        setTimeout(() => scroll.scrollToTop(), location.pathname !== "/" ? 0 : 300)
    }

    const handleRedirection = (path) => {
        navigate(path);
        if (path === "/bonus")
            scroll.scrollToTop();
        if (isOpen) setOpen(false);
    }

    return (
        <div className={`header_external ${isOpen ? "open" : ""}`}>
            <div className={`header_internal ${isOpen ? "open" : ""}`}>
                <a>
                    <img src={require("../../media/svg/WarnMedia.svg").default} onClick={() => toMainPage()} alt="" />
                </a>
                <div className={`burger_menu ${isOpen ? "open" : ""} ${adaptive ? adaptive : ""}`}>
                    <div className={`nav_bar ${isOpen ? "open" : ""} ${adaptive ? adaptive : ""}`}>
                        <ul>
                            <a onClick={() => { scrollFunc("streamers", -200) }} >
                                <li>{content && content[language]?.streamers_button}</li> {/**просмотр */}
                            </a>
                            {/*<a onClick={() => { scrollFunc("", -100) }}>
                                <li>Մեր մասին</li> о нас 
                            </a> */}
                            <a onClick={() => { scrollFunc("partners", -100) }}>
                                <li>{content && content[language]?.partners_button}</li> {/**коллеги */}
                            </a>
                            <a onClick={() => handleRedirection("/bonus")}>
                                <li>{content && content[language]?.roulette_button}</li> {/**коллеги */}
                            </a>
                        </ul>
                    </div>
                    <div className={`language_settings ${languageMenuIsOpen ? "open" : ""}`}
                        style={{ '--langueges-size': `${Object.keys(languages).length * 26 + (Object.keys(languages).length - 1) * 12}px` }} >
                        <div className="language" onClick={() => setLanguageMenu(!languageMenuIsOpen)}>
                            <span>{language}</span>
                            <div className={`language_arrow`} >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 9L12 15L5 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className={`language_list`}>
                            {languages?.map((language) => {
                                return (
                                    <div onClick={() => { setLanguage(language); setLanguageMenu(!languageMenuIsOpen); }}>
                                        <span >{language}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="media_links">
                        {mediaLinks?.map((link) => {
                            return (<a href={link.link} target='_blank'>
                                {link.name === "instagram" && < Instagram_svg />}
                                {link.name === "facebook" && < Facebook_svg />}
                                {link.name === "telegram" && < Telegram_svg />}
                                {link.name === "youtube" && < Youtube_svg />}
                            </a>)
                        })}
                    </div>
                    {
                        user ?
                            <div className="log_out_button" >
                                <img src={user?.photo_url} onClick={() => setModaleType('history')} />
                                <button className="black" onClick={handleLogOut}>{content && content[language]?.log_out_button}</button>
                            </div>
                            :
                            <div className="authorization_button">
                                <button className="orange" onClick={handleAuthorization}>{content && content[language]?.authorization_button}</button>
                            </div>

                    }
                </div>
                <div className="button_block ">
                    <div className={`burger_button ${isOpen ? "open" : ""} `}
                        onClick={() => {
                            setOpen(!isOpen);
                            setLanguageMenu(false);
                        }}>
                        <div className="line top"> <Line_svg /> </div>
                        <div className="line center"> <Line_svg /> </div>
                        <div className="line bottom"> <Line_svg /> </div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default Header;