import { useState } from 'react';
import './PromoBlock.css';



function PromoBlock({ code, onClick }) {

    const copy_svg = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="solar:copy-bold-duotone">
                <path id="Vector" d="M6.59961 11.3963C6.59961 8.67028 6.59961 7.30728 7.44261 6.46028C8.28661 5.61328 9.64361 5.61328 12.3596 5.61328H15.2396C17.9546 5.61328 19.3126 5.61328 20.1556 6.46028C20.9996 7.30728 20.9996 8.67028 20.9996 11.3963V16.2163C20.9996 18.9423 20.9996 20.3053 20.1556 21.1523C19.3126 21.9993 17.9546 21.9993 15.2396 21.9993H12.3596C9.64361 21.9993 8.28661 21.9993 7.44261 21.1523C6.59961 20.3053 6.59961 18.9423 6.59961 16.2163V11.3963Z" fill="#F2994A" />
                <path id="Vector_2" opacity="0.5" d="M4.172 3.172C3 4.343 3 6.229 3 10V12C3 15.771 3 17.657 4.172 18.828C4.789 19.446 5.605 19.738 6.792 19.876C6.6 19.036 6.6 17.88 6.6 16.216V11.397C6.6 8.671 6.6 7.308 7.443 6.461C8.287 5.614 9.644 5.614 12.36 5.614H15.24C16.892 5.614 18.04 5.614 18.878 5.804C18.74 4.611 18.448 3.792 17.828 3.172C16.657 2 14.771 2 11 2C7.229 2 5.343 2 4.172 3.172Z" fill="#F2994A" />
            </g>
        </svg>
    )
    const check_mark_svg = (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M9.50015 16.1698L6.03015 12.6998C5.84317 12.5129 5.58957 12.4078 5.32515 12.4078C5.06072 12.4078 4.80712 12.5129 4.62015 12.6998C4.43317 12.8868 4.32812 13.1404 4.32812 13.4048C4.32813 13.5358 4.35391 13.6654 4.40402 13.7864C4.45412 13.9073 4.52756 14.0173 4.62015 14.1098L8.80015 18.2898C9.19015 18.6798 9.82015 18.6798 10.2101 18.2898L20.7901 7.70983C20.9771 7.52286 21.0822 7.26926 21.0822 7.00483C21.0822 6.74041 20.9771 6.48681 20.7901 6.29983C20.6032 6.11286 20.3496 6.00781 20.0851 6.00781C19.8207 6.00781 19.5671 6.11286 19.3801 6.29983L9.50015 16.1698Z" fill="#F2994A" />
        </svg>
    )
    const [isCopied, setCopied] = useState(false)

    function handleClick(event) {
        event.stopPropagation();

        if (!isCopied) {
            onClick();
            setCopied(true);

            setTimeout(() => {
                setCopied(false)
            }, 2000)
        }

    }

    return (
        <div className={`promoBlock`} onClick={handleClick} >
            <div className={`promo ${isCopied ? "hidden" : ""}`}>
                <span >Promocode:</span>
                <span className={`code`}>{code}</span>
                {copy_svg}
            </div>
            <div className={`copied ${isCopied? "": "hidden"}`}>
                <span className={`${isCopied ? "" : "hidden"}`}>Copied </span>
                {check_mark_svg}
            </div>
        </div>
    )
}

export default PromoBlock;