import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import YouTube from "react-youtube";

import Partner from "../components/Elements/Partner/Partner";
import PromoBlock from "../components/Elements/PromoBlock/PromoBlock";

import { useData } from "../context"

import './styles/StreamPage.css';

function StreamPage() {

  const { streamPage, getStreamerPage, getContent, language, setDisposition } = useData();
  const location = useLocation();
  setDisposition(location.pathname);

  useEffect(() => { 
    getStreamerPage(location.search.split("media=")[1]);
    getContent();
  }, []);


  return (
    <div className="streamPage">
      <div className="main">
        <div className="partners_list">

          {streamPage?.partners?.map((partner, index) => {
            return <Partner img={`${partner.preview}`} link={partner.link} />
          })}
        </div>
        <div className="main_info">
          <div className="media_links">
            <a href={streamPage?.youtube} target='_blank'>
              <img src={require("../media/svg/social_youtube.svg").default} alt="" />
            </a>
            <a href={streamPage?.instagram} target='_blank'>
              <img src={require("../media/svg/social_instagram.svg").default} alt="" />
            </a>
            <a href={streamPage?.facebook} target='_blank'>
              <img src={require("../media/svg/social_facebook.svg").default} alt="" />
            </a>
            <a href={streamPage?.telegram} target='_blank'>
              <img src={require("../media/svg/social_telegram.svg").default} alt="" />
            </a>
          </div>
          <div className="info">
            <p className="title">{streamPage?.name}</p>
            <p className="preview_info">{streamPage?.description[language]}</p>
            <PromoBlock code={streamPage?.promo} onClick={ () => navigator.clipboard.writeText(streamPage?.promo)}/>

          </div>
        </div>
        <div className="stream">
          <YouTube videoId={streamPage?.stream_link}
            opts={{
              width: "100%",
              height: "100%",
              playerVars: {
                autoplay: 1,
                mute: 1,
              }
            }} />
          {/* <ReactTwitchEmbedVideo channel="makatao" layout={"video"} muted="true" width="100%" height="100%" /> */}
        </div>


      </div>

    </div>
  )
};

export default StreamPage;
