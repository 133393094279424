import { useLocation } from 'react-router-dom';

import './BackgroundElements.css'
import Lights from '../Light/Lights';

import { useData } from '../../context';

function BackgroundElements() {

    const { disposition } = useData();
    

    return (
        <div className={`background_elements ${disposition === "/" ? "" : "nonManePage"}`} >
            {disposition !== "/bonus" && <img className="background" src={require("../../media/img/background.png")} />}
            {disposition === "/bonus" && <img className="background bonus_img" src={require("../../media/img/bg_bonus.png")} />}
            <img className={`element ${disposition === "/" ? "" : "nonManePage"} raspberry`} src={require("../../media/img/raspberry.png")} alt="" />
            <img className={`element ${disposition === "/" ? "" : "nonManePage"} gold`} src={require("../../media/img/gold.png")} alt="" />
            <img className={`element ${disposition === "/" ? "" : "nonManePage"} gold_strawberry`} src={require("../../media/img/gold_strawberry.png")} alt="" />
            <img className={`element ${disposition === "/" ? "" : "nonManePage"} plum`} src={require("../../media/img/plum.png")} alt="" />
            <img className={`element ${disposition === "/" ? "" : "nonManePage"} dog`} src={require("../../media/img/dog.png")} alt="" /> 
            <img className={`element ${disposition === "/" ? "" : "nonManePage"} dog_smartphone`} src={require("../../media/img/dog_smartphone.png")} alt="" /> 
            {}
            {/* <video preload="auto" muted playsInline autoPlay loop>
                <source src={require("../../media/video/sparks.mp4")} type="video/mp4" />
            </video> */}

            {disposition !== "/bonus" && <Lights />}
        </div>
    )
}

export default BackgroundElements;