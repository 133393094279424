import './Lights.css'

function Lights () {
    return (
        <div className="lights">
            <img className="ellipse e1" src={require("../../media/img/e1.png")} alt="" />
            <img className="ellipse e2" src={require("../../media/img/e2.png")} alt="" />
            <img className="ellipse e3" src={require("../../media/img/e3.png")} alt="" /> 
            <img className="ellipse e4" src={require("../../media/img/e3.png")} alt="" /> 
            {/* <div className="ellipse e_preview"></div> */}
        </div>
    )
}

export default Lights;