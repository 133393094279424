import { useEffect, useState, useRef } from "react";
import { useData } from "../../context";

import History from "./Elements/History/History";
import Authorization from "./Elements/Authorization/Authorization"
import Promocode from "./Elements/Promocode/Promocode";
import Prize from "./Elements/Prize/Prize";

import './Modale.css';

const Modale = () => {

    const { modaleType, setModaleType } = useData();
    const [showModale, setShowModale] = useState(false);
    const modalRef = useRef(null);


    const handleClickOutside = (event) => {
        if (modalRef.current === event.target) {
            setModaleType(null);
        }
      };

    useEffect(() => {
        setShowModale(!!modaleType);
    }, [modaleType])

    useEffect(() => {
        if (modaleType) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [modaleType]);

    if (!showModale) return null
    return (
        <div className="modale" ref={modalRef}>
            <div className="modale_window" >
                <div className="button" onClick={() => setModaleType(null)}> <img src={require("../../media/svg/cross.svg").default} alt="" /></div>

                {modaleType === "authorization" && <Authorization />}
                {modaleType === "history" && <History />}
                {modaleType === "promocode" && <Promocode />}
                {modaleType === "prize" && <Prize />}

            </div>

        </div>
    );
}

export default Modale;