import { useData } from "../../../../context";

import Text from "../../../Elements/Text/Text";

import "./NotifiComponent.css";

const NotifiComponent = ({ el }) => {

    const {content, language} = useData(); 

    return (
        <div className="notifi-component">
            <div className="background-light"></div>
            <div className="notifi-box" style={{ '--notifi-color': `${el.type.color}` }}>

                <img className="notifi-light" src={require(`../../../../media/svg/${el.type.title}_light.svg`)} alt="" />

                <Text big={ content && content[language][`notifi_title_${el.type.title}`] } size_b="18px" gap="8px" small={el.text} size_s="14px" />
            </div>
        </div>
    );
}

export default NotifiComponent;