import ReactDOM from 'react-dom/client';

import { ContextProvider } from './context';
import Navigate from './navigate';

import './styles/index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ContextProvider>
    <Navigate />
  </ContextProvider>
);


