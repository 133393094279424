import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useData } from './context';

import Bonus from './pages/Bonus';
import Header from './components/Header/Header';
import Content from './pages/Content';
import StreamPage from './pages/StreamPage'
import BackgroundElements from "./components/BackgroundElements/BackgroundElements";
import Modale from './components/Modale/Modale';
import Notifi from './components/Notify/Notifi';


const Navigate = () => {
    const { getStreamersList, getPartnersList, getContent, modaleType } = useData();

    useEffect(() => {
        getStreamersList();
        getPartnersList();
        getContent();
    }, [])

    return (<>
        <BrowserRouter>
            <Header />
            <Modale />
            <Notifi />
            <Routes>
                <Route exact path="/" element={<Content />} />
                <Route path="/streamer" element={<StreamPage />} />
                <Route path="/bonus" element={<Bonus />} />
            </Routes>
        </BrowserRouter>
        <footer />
        <BackgroundElements />
    </>

    )

}

export default Navigate;