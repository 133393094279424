import { useData } from "../../../../context";

import "./Prize.css"

const Prize = () => {

    const { prize, content, language, setModaleType } = useData();

    return (
        <div className="prize">
            <span className="modale_title"></span>
            <img src={`http://warnmedia.am${prize.img}`} alt="" />

            {
                prize.empty ?
                <span className="modale_title">{prize.name || "Ничего"}</span>
                :  <span className="modale_title">{prize.name}</span>
            }

            {prize.empty ?
                <button className="orange" onClick={()=> setModaleType(null) } >{content[language]?.okay_button}</button>
                :
                <a href="https://t.me/authtest123_bot" target="_blank">
                    <button className="orange">
                        {content && (content[language]?.contact_support_button || "call to support")}
                    </button>
                </a>

            }
        </div>
    )
}

export default Prize;