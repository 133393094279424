
import "./Text.css"

function Text ({big, size_b = "64px", fontWeight_b = 500, lh_big = "130%", small, size_s = "22px", fontWeight_s = 400, lh_small = "140%", gap= "20px"}){
    return(
        <div className="text" >
            <p className="title" style={{ fontSize:size_b, fontWeight: fontWeight_b, lineHeight:lh_big, marginBottom:gap }}>{big}</p>
            <p className="title_text" style={{ fontSize:size_s, fontWeight: fontWeight_s, lineHeight:lh_small }}>{small}</p>
        </div>
    )
}

export default Text;