import { useData } from "../../../../context"

import "./Promocode.css"

const Promocode = () => {

    const {setPromocode} = useData();

    const handleClick = () => {
        const code = document.getElementById("promocode").value
        setPromocode(code);
        
        if(!code){
            alert("Введите промокод!");
            return
        }
    }


    return (
        <div className="promocode">
            <span className="modale_title">Промокод</span>
            <span>Учавствуйте в розыгрыше призов</span>

            <input id="promocode" type="text" placeholder="Введите промокод"/>

            <button className="orange" onClick={handleClick}>Կազմը</button>


        </div>
    )
}

export default Promocode;